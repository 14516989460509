import {
  MicrocopyFragment,
  RichMicrocopyFragment,
} from '@graphql/generated-contentful/graphql';
import { isDefined } from './isDefined';

export type Microcopy = {
  key: string;
  value: string;
};

export enum MicrocopySlug {
  USER_PROFILE = 'user-profile',
  HEADER = 'header',
  LAYOUT = 'layout',
  SEARCH = 'search',
  SEARCH_VALUES = 'search-ui-values',
  PRODUCT_CARD = 'product-card',
  PAGINATION = 'pagination',
  PRODUCT_DETAILS_PAGE = 'pdp',
  CATEGORIES = 'categories',
  LIVESTREAM = 'livestream',
}

export const getMicrocopySet = (
  namespace: MicrocopySlug,
  collection: Microcopy[]
) => {
  return (key: string) =>
    collection?.find((microcopy) => microcopy.key === `${namespace}.${key}`)
      ?.value;
};

export const convertArrayToObject = (inputArray) => {
  const resultObject = {};

  if (Array.isArray(inputArray)) {
    inputArray.forEach((item) => {
      if (item && item.key) {
        resultObject[item.key] = item.value;
      }
    });
  }

  return resultObject;
};

export const formatContentfulMicrocopy = (
  microcopy: MicrocopyFragment[]
): Record<string, string> => {
  const formattedMicrocopy: Record<string, string> = {};
  const definedMicrocopy = microcopy.filter(
    (item): item is MicrocopyFragment =>
      isDefined<MicrocopyFragment>(item) &&
      isDefined(item.value) &&
      isDefined(item.key)
  );

  definedMicrocopy.forEach((microcopyItem) => {
    formattedMicrocopy[microcopyItem.key!] = microcopyItem.value!;
  });

  return formattedMicrocopy;
};

export function isContentfulRichMicrocopy(
  microcopy: MicrocopyFragment | RichMicrocopyFragment
): microcopy is RichMicrocopyFragment {
  return (microcopy as RichMicrocopyFragment).richValue !== undefined;
}
